import { Image, Link, RawHtml, Text } from '~/shared/components';
import React from 'react';
import { MediaAndTextItem } from '~/lib/data-contract';
import { queries } from '~/theme/breakpoints';
import { StyledActionWrapper, StyledArticle, StyledContent } from './styled';
import { Button } from '~/shared/components/Button/Button';

export type ArticleProps = MediaAndTextItem;

export const Article = ({
    headline,
    image,
    imageAltText,
    description,
    callToAction,
}: ArticleProps) => {
    return (
        <StyledArticle>
            {image ? (
                <Image
                    alt={imageAltText ?? ''}
                    {...image}
                    height={300}
                    width={340}
                    objectFit="cover"
                    sizes={`${queries.md} 340px, 230px`}
                />
            ) : null}
            <StyledContent>
                {headline ? <Text variant="display4" children={headline} /> : null}
                {description ? <RawHtml html={description} /> : null}
                {callToAction ? (
                    <StyledActionWrapper>
                        <Button
                            as={Link}
                            variant="plain"
                            href={callToAction.url}
                            children={callToAction.text}
                            target={callToAction.target}
                        />
                    </StyledActionWrapper>
                ) : null}
            </StyledContent>
        </StyledArticle>
    );
};
